import Game1 from "../../Components/Game/Game1";

export const Days = [{
    id: 1, content:
        <>
            <h2>Mézeskalácsos trüffelgolyó
                - tuti karácsonyi ajándék</h2>
            <div>
                <img src="assets/Group 361.png" alt="" className={'my-4'}/>
            </div>
            <h4>Ismét egy szuper karácsonyi ajándékötlet, ami tényleg sokáig eláll a hűtőben. Használjatok jó minőségű
                étcsokit, sok-sok fűszert és magas kakaótartalmú, holland kakaóport és higgyétek el, hogy isteni finom
                lesz
                a végeredmény ;)</h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>120 ml zsíros tejszín</li>
                        <li>300 g étcsoki</li>
                        <li>100 g puha vaj</li>
                        <li>1 mk őrölt gyömbér</li>
                        <li>1 mk őrölt szegfűszeg</li>
                        <li>2 mk őrölt fahéj</li>
                        <li>1/2 mk őrölt szerecsendió</li>
                        <li>1 db narancs reszelt héja</li>
                        <li>3 ek holland kakaópor</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A gasztroajándékkal sosem lőhettek mellé, így akiknek nincs ötletetek, hogy mit adjatok
                        karácsonyra,
                        egy saját készítésű, ünnepi csomagolású süti mindig jó meglepetés. A mézeskalácsos
                        trüffelgolyóval
                        biztos sikeretek lesz. A kevésbé édesszájú ismerősök is odáig lesznek érte, és az a jó benne,
                        hogy
                        nem kell rengeteg energiát fektetni az elkészítésébe.
                    </p>
                    <p>
                        Az étcsokit leöntjük a felmelegített tejszínnel, belerakjuk a puha vajat, reszelt narancshéjat,
                        és
                        beleszórjuk a fűszereket. Elkeverjük, hogy a vaj és a csoki is feloldódjon a meleg tejszínben.
                    </p>
                    <p>
                        Az alapot bedobjuk a hűtőbe, időnként átkeverjük, és amikor már megdermedt, kézzel kisebb
                        golyókat
                        formálunk a masszából, és meghempergetjük őket a kakaóporban. A karácsonyi asztalon is
                        találhatjuk,
                        de pici zacskókba rakva szuper ajándék lesz, a hűtőben pedig sokáig eláll.
                    </p>
                </div>
            </div>
        </>
},
    {
        id: 2, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QJ5DOWPGxwg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/QJ5DOWPGxwg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 3, content:
            <>
                <h4 className={'text-center'}>Szeretnél résztvenni adventi sorsolásunkon?</h4>
                <h2 className={'text-center md:text-[30px] md:leading-[32px]'}>A nyertes 20.000 Ft értékű könyvutalványt
                    kap
                    ajándékba! </h2>
                <div>
                    <img src="assets/rajz.png" alt="" className={'my-5 mx-auto md:max-w-[600px]'}/>
                </div>
                <h4 className={'text-center'}>Jelentkezz neved és e-mail címed megadásával</h4>
                <div className={'flex max-w-[500px] mx-auto flex-col'}>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="name"
                                                                                className={'bg-fontBase p-2 pr-16 text-white font-bold rounded-l-lg uppercase'}>Név</label><input
                        type="text"
                        id={'name'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="email"
                                                                                className={'bg-fontBase p-2  text-white font-bold rounded-l-lg uppercase'}>Email&nbsp;Cím</label><input
                        type="email" id={'email'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div><input type="checkbox" id={'checkbox'}/><label htmlFor="checkbox"> Adatvédelmi és
                        játékszabályzatot
                        megértettem és elfogadom.</label></div>
                    <div className="btn-primary mt-4">Beküldés</div>
                </div>
            </>
    },
    {
        id: 4, content:
            <>
                <Game1/>
            </>
    },
    {
        id: 5, content: <>
            <h2> Mákos bejgli</h2>
            <div>
                <img src="assets/bejgli.jpg" alt="" className={'my-4'}/>
            </div>
            <h4>Mákos bejgli nélkül nincs karácsony egy diótagadó embernek sem. Próbáljátok ki, és legyen mákotok!</h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <h4 className={'mb-2'}>A tésztához</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>250 g liszt</li>
                        <li>25 g porcukor</li>
                        <li>1 csipet só</li>
                        <li>60 g vaj</li>
                        <li>50 g sertészsír</li>
                        <li>4 g friss élesztő</li>
                        <li>50 ml tej</li>
                        <li>2 db tojássárgája</li>
                    </ul>
                    <h4 className={'mb-2'}>A töltelékhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>250 g darált mák</li>
                        <li>12 g vaníliás cukor</li>
                        <li>1 tk őrölt fahéj</li>
                        <li>1 db citrom reszelt héja</li>
                        <li>1 db narancs leve és reszelt héja</li>
                        <li>20 g mazsola</li>
                        <li>20 g kandírozott narancshéj</li>
                    </ul>
                    <h4 className={'mb-2'}>A töltelék leforrázásához</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>80 ml tej</li>
                        <li>100 g cukor</li>
                        <li>50 g méz</li>
                    </ul>
                    <h4 className={'mb-2'}>A kenéshez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1 db tojás</li>
                        <li>2 db tojásfehérja</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A mákos bejgli tésztájának elkészítéséhez a lisztet összekeverjük a porcukorral és a sóval, majd
                        elmorzsoljuk benne a vajat és a zsírt. A cél az, hogy morzsalékos állagot kapjunk. Az élesztőt
                        elmorzsoljuk a tejben, belekeverjük a tojássárgáját (a fehérjét ne dobjuk el, jó lesz a
                        kenéshez), majd a lisztes keverékhez adjuk, és összedolgozzuk. Nem kell vele túl sokat
                        foglalkozni, hamar egynemű lesz. A tésztát kettéválasztjuk – mérleggel biztosabb a dolog, mi 2 x
                        240 grammosra osztottuk. A két tésztaadagot gömbölyűre formázzuk, és frissentartó fóliával
                        lefedve a hűtőbe tesszük egy órára.
                    </p>
                    <p>
                        A töltelékhez összekeverjük a mákot, a vaníliás cukrot, a fahéjat, a citrom- és narancshéjat, és
                        hozzáadjuk az apróra vágott mazsolát is, amit így még azok is el tudnak fogadni, akik nem
                        szeretik. Belekeverjük a kandírozott narancshéjat is – amit ha nem tudtok beszerezni, nyugodtan
                        elhagyhatjátok.
                    </p>
                    <p>
                        A tejet egy kis lábasba öntjük, majd a cukorral, a mézzel és a narancs kifacsart levével együtt
                        felforraljuk, ráöntjük a mákos töltelékre, és összekeverjük. A cél, hogy gyurmázható állagot
                        kapjunk. A masszát lefedjük és 30 percre hűtőbe tesszük.
                    </p>
                    <p>
                        A tésztát kivesszük a hűtőből, és két, kb. 30 cm széles, 20 cm hosszú téglalapot nyújtunk
                        belőle.
                    </p>
                    <p>
                        A tölteléket is kivesszük a hűtőből, elfelezzük, és két frissentartó fólia között két, kb. 28 x
                        18 cm-es téglalapot formázunk belőle. Lehúzzuk róluk az egyik fóliát, és fejjel lefelé a két
                        tészta közepére borítjuk. A tészták kétoldali széleit behajtogatjuk a töltelék magasságáig, és
                        feltekerjük. Egy villával mélyen megszurkáljuk, majd megkenjük a felületüket egy enyhén felvert
                        tojással. Hűvös helyre tesszük a rudakat, és hagyjuk, hogy a tojásréteg rászáradjon. Ezután a
                        megmaradt és félretett fehérjét is kicsit felkeverjük, és megkenjük vele a bejglik tetejét, majd
                        újra hagyjuk, hogy rászáradjon. Ez a mozzanat teszi márványossá a bejgli felszínét sütés közben.
                    </p>
                    <p>
                        A rudakat 200 fokra előmelegített sütőbe tesszük 15 percre. Az idő letelte után forgatunk egyet
                        a tepsin, és további 10-15 percre visszatesszük, de csak 185 fokon. Felvágás előtt legalább 2-3
                        órát hagyjuk hűlni, de akkor az igazi, ha csak másnap vágjuk meg.
                    </p>
                </div>
            </div>
        </>
    },
    {
        id: 6, content: <>
            <h2>Hangolódj az Ünnepekre!</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/E8gmARGvPlI"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/E8gmARGvPlI"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
        </>
    },
    {
        id: 7, content: <>
            <h4 className={'text-center'}>Szeretnél résztvenni adventi sorsolásunkon?</h4>
            <h2 className={'text-center md:text-[30px] md:leading-[32px]'}>A nyertes 20.000 Ft értékű könyvutalványt kap
                ajándékba! </h2>
            <div>
                <img src="assets/rajz.png" alt="" className={'my-5 mx-auto md:max-w-[600px]'}/>
            </div>
            <h4 className={'text-center'}>Jelentkezz neved és e-mail címed megadásával</h4>
            <div className={'flex max-w-[500px] mx-auto flex-col'}>
                <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="name"
                                                                            className={'bg-fontBase p-2 pr-16 text-white font-bold rounded-l-lg uppercase'}>Név</label><input
                    type="text"
                    id={'name'}
                    className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                </div>
                <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="email"
                                                                            className={'bg-fontBase p-2  text-white font-bold rounded-l-lg uppercase'}>Email&nbsp;Cím</label><input
                    type="email" id={'email'}
                    className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                </div>
                <div><input type="checkbox" id={'checkbox'}/><label htmlFor="checkbox"> Adatvédelmi és játékszabályzatot
                    megértettem és elfogadom.</label></div>
                <div className="btn-primary mt-4">Beküldés</div>
            </div>
        </>
    },
    {
        id: 8, content: <>
            <Game1/>
        </>
    },
    {
        id: 9, content: <>
            <h2> Magyarország kedvence: a töltött káposzta </h2>
            <div>
                <img src="assets/img_7443.jpg" alt="" className={'my-4'}/>
            </div>
            <h4>Magyarországon a karácsony, de igazából az egész téli szezon megkerülhetetlen nagyágyúja a töltött
                káposzta. Nem éppen a szuperlazán, 20 perc alatt összedobható ételek közé tartozik, de a végeredmény
                önmagáért beszél.</h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <h4 className={'mb-2'}>Az aprókáposzta alaphoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1.8 kg savanyú káposzta</li>
                        <li>2 nagy ek sertés zsír</li>
                        <li>4 fej közepes vöröshagyma</li>
                        <li>8 közepes gerezd fokhagyma</li>
                        <li>1 nagy ek édesnemes fűszerpaprika</li>
                        <li>1 mk csípős fűszerpaprika</li>
                        <li>½ mk őrölt bors</li>
                        <li>25 dkg sertésdagadó</li>
                        <li>15 dkg hasaalja szalonna</li>
                        <li>10 dkg füstölt kolbász</li>
                        <li>35 dkg füstölt oldalas</li>
                    </ul>
                    <h4 className={'mb-2'}>A töltelékhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>25 dkg hasaalja szalonna</li>
                        <li>50 dkg sertésdagadó</li>
                        <li>2 tk zsír</li>
                        <li>1 fej kisebb vöröshagyma</li>
                        <li>8 közepes gerezd fokhagyma</li>
                        <li>30 dkg rizs</li>
                        <li>½ l víz</li>
                        <li>2 egész tojás</li>
                        <li>1 ek édesnemes fűszerpaprika</li>
                        <li>1 tk csípős fűszerpaprika</li>
                        <li>őrölt bors</li>
                        <li>só</li>
                    </ul>
                    <h4 className={'mb-2'}>A rántáshoz</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1 nagy ek sertés zsír</li>
                        <li>1 púpozott ek liszt</li>
                        <li>1 púpozott tk édesnemes fűszerpaprika</li>
                        <li>1 ½ dl hideg víz</li>
                        <li>2 dl tejföl</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <h4>A töltelékhez:</h4>
                    <p>A finomra vágott vöröshagymát egy lábasban a forró zsíron üvegesre párolunk, hozzáadjuk a zúzott
                        fokhagymát, lehúzzuk a tűzről, rádobjuk a fűszerpaprikát és rögtön felöntjük 1,5 dl vízzel.
                        Sózzuk, borsozzuk és hozzáadjuk a nagyobb darabokra vágott dagadót, lebőrözött és felkockázott
                        hasaalja szalonnát, a szintén nagyobbra darabolt füstölt oldalast, a vastag karikákra vágott
                        füstölt kolbászt és a hasaalja szalonna bőrét is. Alacsony lángon kb. 1,5 órát főzzük és közben
                        elkészítjük a tölteléket:
                    </p>
                    <p>
                        Egy lábasban felforraljuk a vizet egy teáskanál zsírral és két teáskanál sóval, hozzáöntjük a
                        rizst és 2-3 percig főzzük, majd fedő alatt, 120 fokos sütőben 20 perc alatt 80%-osra pároljuk.
                        Ha kész, a rizst egy tepsin szétterítjük, hogy minél előbb kihűljön. Közben a tölteléknek szánt
                        dagadót és bőr nélküli hasaalja szalonnát kockára vágjuk és ledaráljuk. A vöröshagymát és a
                        zúzott fokhagymát egy teáskanál zsíron aranyszínűre pirítjuk és félretesszük. Összekeverjük a
                        hozzávalókat: a darált húst és szalonnát, a párolt rizst, a tojásokat és a lepirított
                        hagymát-fokhagymát. A masszát sóval, borssal, fűszerpaprikával és ízlés szerint csípős
                        pirospaprikával ízesítjük. A begyúrt tölteléket rövid időre hűtőbe tesszük, majd 18 db egyforma
                        gombócot formázunk belőle.
                    </p>
                    <h4>
                        A töltés:
                    </h4>
                    <p>
                        Elkészítjük a töltött káposztákat: a levelek torzsájának kiálló zömök részét egy késsel lapjában
                        levágjuk, hogy minél vékonyabb legyen. Ha nagyok a káposztalevelek, akkor a szélüket és a
                        tetejüket is lenyisszantjuk. A megformázott, szépen kiterített levél aljára helyezünk egy kissé
                        hengeresre formázott gombócot, majd alulról jó szorosan feltekerjük és a két oldalát szintén
                        szorosan begyömöszöljük, hogy egy szép, zárt, feszes töltött levelet kapjunk. Ezt megismételjük
                        az összes gombóccal.
                    </p>
                    <p>
                        Ha a kolbászos-dagadós-oldalasos pörköltünk elkészült, akkor rápakoljuk az ízlés szerint
                        átöblített, vagy savanyún hagyott aprókáposzta 80%-át (nem is kell átkavarni) és ezen a káposzta
                        ágyon szorosan elhelyezzük két rétegben a betöltött káposztákat. A tetejükre rakjuk a megmaradt
                        aprókáposztát, az egészre öntünk 2 dl vizet és lefedve alacsony lángon 1,5 órát pároljuk. A hő
                        hatására a pörköltes gőz szépen átjárja majd az egész káposztát és elkészíti a levelekben lévő
                        tölteléket is.
                    </p>
                    <p>
                        Ha letelt a párolásra szánt 1,5 óra, befejezésképp rántást (igazából egy rántásos habarást)
                        készítünk: kevés zsíron zsemleszínig hevítjük a lisztet, lehúzzuk a tűzről, hozzáadjuk a
                        pirospaprikát, elkeverjük és felöntjük hideg vízzel. Belekeverjük a tejfölt és az egészet
                        hozzáöntjük a kész aprókáposztás alaphoz úgy, hogy előtte a töltött káposztákat kiszedtük a
                        fazékból. Az aprókáposztát jól összekeverjük a habarással, további öt percig rotyogtatjuk és
                        kész is vagyunk. Én a töltött-, és az aprókáposztát így külön tárolom és csak tálalásnál rakom
                        őket egybe, igény szerinti arányban. Nálam a két töltelék, sok apró és sok kiszemezgetett húsos
                        rész a nyerő, nagy adag tejföllel a tetején.
                    </p>
                </div>
            </div>
        </>
    },
    {
        id: 10, content: <>
            <h2>Hangolódj az Ünnepekre!</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yXQViqx6GMY"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/yXQViqx6GMY"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
        </>
    },
    {
        id: 11,
        content: <>
            <h2 className={'text-center mb-5'}>Hány korszakból áll egy férfi élete?</h2>
            <img src="assets/109444.640.jpg" alt="" className={'mx-auto'}/>

        </>
    },
    {
        id: 12,
        content: <>
            <h2>Különleges vegán recept karácsonyra - Vegán pásztorpite lencséből</h2>
            <div>
                <img src="assets/vegan.jpg" alt="" className={'my-4'}/>
            </div>
            <h4>A karácsonyi ebéd vagy vacsora vegánul is lehet kulináris élmény, íme egy izgalmas, ámde könnyen
                elkészíthető fogás.</h4>
            <div className="grid md:grid-cols-4 ">
                <div>
                    <h3>Hozzávalók</h3>
                    <h4 className="mb-2">A töltelékhez:</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>20 dkg száraz lencse</li>
                        <li>25 dkg barna csiperkegomba</li>
                        <li>20 dkg sárgarépa, takarosra kockázva</li>
                        <li>15 dkg zöldborsó</li>
                        <li>3 evőkanál sűrített paradicsom</li>
                        <li>2 nagy fej hagyma (bármilyen: vörös, lila, mogyoró, stb.)</li>
                        <li>4-5 gerezd zúzott fokhagyma (ízlés szerint lehet több vagy kevesebb is)</li>
                        <li>1-1 teáskanál szárított rozmaring és kakukkfű (rozmaring- vagy kakukkfűutálók
                            helyettesíthetik más zöldfűszerekkel is)
                        </li>
                        <li>2-3 babérlevél</li>
                        <li>1 liter zöldségalaplé (ízfokozó- és tartósítószermentes zöldségleveskockából vagy
                            poralapból)
                        </li>
                        <li>1-1 teáskanál édes fűszerpaprika és füstölt paprika</li>
                        <li>2 evőkanál szójaszósz</li>
                        <li>2 evőkanál sörélesztőpehely</li>
                        <li>2 evőkanál olívaolaj</li>
                        <li>másfél dl vörösbor</li>
                        <li>só</li>
                        <li>frissen őrölt bors</li>

                    </ul>
                    <h4 className="mb-2">A tetejére:</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1 kg főzni való krumpli</li>
                        <li>4-5 evőkanál jó minőségű vegán margarin (ha nem jutsz hozzá, inkább olívaolajat használj)
                        </li>
                        <li>4 evőkanál sörélesztőpehely</li>
                        <li>só</li>
                        <li>frissen őrölt bors</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A töltelékkel kezdjük: a hagymát kockázd finomra, és a legnagyobb, magasított falú serpenyődben
                        kezdd el alacsony lángon pirítani egy-két csipet sóval. Miközben pirul, a gombát a botmixer
                        késes aprítójában aprítsd össze, vagy ha ilyened nincs, akkor vágd apróra.
                    </p>
                    <p>
                        Ha a hagyma már 7-8 perce pirul, add hozzá a gombát, még egy evőkanál olívaolajat és két nagy
                        csipet sót. A lángot vedd közepesen magasra, és addig pirítsd, amíg a gomba összes leve teljesen
                        el nem párolgott.
                    </p>
                    <p>
                        Vedd a lángot közepesre, add hozzá a fokhagymát, a kakukkfüvet és a rozmaringot, és pirítsd 2-3
                        percig
                    </p>
                    <p>
                        Most jön a sűrített paradicsom: ha hozzáadtad, ezt is pirítsd két-három percig (ez mindig fontos
                        lépés a sűrített paradicsomnál — így lesz a végeredmény a legízletesebb), majd öntsd hozzá a
                        vörösbort, és 2-3 percig kevergesd.
                    </p>
                    <p>
                        Tedd a serpenyőbe a felkockázott répát, a zöldborsót, a babérlevelet és a paprikát, majd öntsd
                        hozzá a zöldségalaplevet.
                    </p>
                    <p>
                        Add hozzá a lencsét és vedd a lángot magasra. Ha felforrt, vedd vissza a lángot, hogy épp csak
                        rotyogjon.
                    </p>
                    <p>
                        Miközben a lencse fő, elkészítjük a tölteléket: a krumplit hámozd meg, kockázd fel, és főzd
                        puhára. A sütőt kb. mostanában érdemes előmelegíteni 200 fokra.
                    </p>
                    <p>
                        Ha a krumpli megpuhult, öntsd le róla a főzővizet, krumplinyomóval törd össze, majd keverj hozzá
                        négy evőkanál sörélesztőpelyhet, sót (ne spórold ki belőle — legyen jó sós, sütés után ugyanis
                        enyhébb íze lesz), frissen őrölt borsot, és annyi jó minőségű margarint vagy olívaolajat,
                        amennyitől szép krémes lesz (kb. 4-5 evőkanál). Nem baj, ha nem töröd teljesen simára, de ne
                        maradjanak benne túl nagy darabok sem.
                    </p>
                    <p>
                        A lencse kb. 25-30 perc alatt puhára fő (a tölteléknek pedig jó sűrűnek kell lennie — ha még nem
                        az, akkor főzd tovább): ha ez megtörtént, halászd ki belőle a babérleveleket, és adj hozzá
                        két-két evőkanál szójaszószt és sörélesztőpelyhet, valamint frissen őrölt borsot és ízlés
                        szerint sót.
                    </p>
                    <p>
                        A tölteléket egy közepesen nagy tepsiben simítsd el, majd kanalazd rá a krumplit, és egy
                        kanállal igazgasd szép simára. Villával megmintázhatod, ha szeretnéd.
                    </p>
                    <p>
                        Kb. 20-25 perc alatt készre sül. Fontos, hogy mielőtt felvágod, hagyd legalább 20 percig a
                        tepsiben, különben nagyon szét fog esni.
                    </p>
                </div>
            </div>
        </>
    },
    {
        id: 13, content:
            <>
                <h2>Top100: kihagyhatatlan karácsonyi filmek decemberre</h2>
                <h4>Válassz a listában szerepló filmek közül, és hagyd, hogy magával ragadjon a karácsonyi
                    hangulat!</h4>
                <div className="grid ">
                    <div>
                        <div className="col-xs-12">


                            <p>1.<strong> Grincs </strong>– Jim Carrey, Kik, karácsony</p>

                            <p>2. <strong>Reszkessetek betörők</strong> – nincs karácsony
                                Kevin nélkül</p>

                            <p>3.<strong> Reszkessetek betörők 2</strong>. - továbbra sincs
                                karácsony Kevin nélkül</p>

                            <p>4. <strong>Reszkessetek betörők 3.</strong> - van karácsony
                                Kevin nélkül, de minek?!</p>

                            <p>5. <strong>Let It Snow</strong> - szabadnapos sztár és a
                                tipikus lány a szomszédból találkozása a Netflixen</p>

                            <p>6.&nbsp;<strong>Holiday</strong> – Amerika, Anglia, szerelem,
                                karácsony</p>

                            <p>7. <strong>Hull a pelyhes</strong> – Turbo Man, Arnold
                                Schwarzenegger, karácsonyi őrület</p>

                            <p>8. <strong>Igazából szerelem</strong> – kétségtelenül a
                                valaha volt legjobb karácsonyi film</p>

                            <p>9. <b>Last Christmas -&nbsp;</b>a sárkányok anyja,&nbsp;a
                                Crazy Rich Asians főszereplője, London és persze karácsony</p>
                            <p>10. <strong>Tapló télapó</strong>- a Mikulás bácsi bunkó,
                                faragatlan kiadása</p>

                            <p>11. <strong>A Szépség és a Szörnyeteg-Varázslatos
                                karácsony</strong> - bizony, amíg Belle a kastélyban raboskodott, volt karácsony is</p>

                            <p>12. <strong>Négy karácsony</strong> – nincs karácsony család
                                nélkül, főleg, ha négy van belőle</p>

                            <p>13. <strong>Szerelem a végzeten</strong> – New York, hóesés,
                                szerelem</p>

                            <p>14. <strong>Télapu</strong> – ennél cukibb karácsonyi film
                                aligha létezik</p>

                            <p>15. <strong>Télapu 2.</strong> - a Mikulás feleséget keres
                            </p>

                            <p>16. <strong>Télapu 3.</strong> - már megint meg kell menteni
                                a karácsonyt</p>

                            <p>17. <strong>Karácsonyi rémek</strong> – karácsonyi ének
                                kicsit másként</p>

                            <p>18. <strong>Nicsak, ki beszél most!</strong> - beszélő
                                kutyák, gyerekek, karácsony</p>

                            <p>19. <strong>Karácsonyi lidércnyomás</strong> – Rosszcsont
                                Jack, Halloween, karácsony</p>

                            <p>20.<strong> The Knight Before Christmas </strong>- időutazás,
                                lovag és persze szerelem karácsonykor</p>

                            <p>21. <strong>Karácsonyi ének </strong>– Charles
                                Dickens&nbsp;klasszikus meséje animálva, Jim Carreyvel</p>

                            <p>22. <strong>Bunyó karácsonyig</strong> – Bud Spencer és
                                Terence Hill karácsonyra</p>

                            <p>23. <strong>Polár expressz</strong> – cuki animációs mese a
                                karácsony szelleméről</p>

                            <p>24. <strong>Hóbarát </strong>– sírni ér</p>

                            <p>25. <strong>Karácsonyi vakáció</strong> – egy igazi
                                kihagyhatatlan klasszikus</p>

                            <p>26. <strong>Szellemes karácsony</strong> – ismét egy afféle
                                karácsonyi ének feldolgozás</p>

                            <p>27. <strong>Kelekótya karácsony</strong> – a karácsony, amit
                                nem lehet kihagyni</p>

                            <p>28. <strong>Karácsony Artúr</strong> – egyetlen gyermek sem
                                maradhat ki a karácsonyból</p>

                            <p>29. <strong>Csoda New York-ban</strong> – a karácsony
                                ünnepének valódi értelme</p>

                            <p>30. <strong>Túlélni a karácsonyt</strong>- bérelt család az
                                ünnepekre</p>

                            <p>31. <strong>Télbratyó</strong> – logikus, hogy a Télapónak
                                van egy nem túl jó fej öccse</p>

                            <p>32. <strong>Kőkemény család </strong>– ünnepi családi dráma
                            </p>

                            <p>33. <strong>Segítség, karácsony</strong> – így ünnepel a
                                lelki segélyszolgálat</p>

                            <p>34.<strong>Huncut karácsony</strong> – elkényeztetett
                                kislány, dada, Plaza Hotel</p>

                            <p>35. <strong>A karácsony története</strong> – szívmelengető
                                családi film</p>

                            <p>36. <strong>Mi a manó? </strong>- Buddy, a két méter magas
                                karácsonyi manó</p>

                            <p>37. <strong>Látástól Mikulásig </strong>– gimis fiú a pácban,
                                úton Kaliforniából New Yorkba</p>

                            <p>38. <strong>Szörnyecskék</strong> – kinek jutna eszébe, de
                                igen, ez is karácsonykor van</p>

                            <p>39. <strong>Ki a Télapó?</strong> - amnéziás télapó az
                                emlékeit keresi</p>

                            <p>40. <strong>S</strong><strong>anta Baby</strong> – bizony,
                                van egy ilyen film is</p>

                            <p>41. <strong>Richie Rich 2.</strong>-A rosszcsont karácsonya –
                                Richie Rich ünnepel</p>

                            <p>42. <strong>Karácsony</strong> – igen, van egy karácsonyi
                                film, aminek egyszerűen csak ez a címe</p>

                            <p>43. <strong>Karácsonyi fények </strong>– művész fiú
                                karácsonyra</p>

                            <p>44. <strong>Mrs. Télapó</strong> – a Télapónak feleséget kell
                                keresni</p>

                            <p>45. <strong>White Christmas</strong> – aki nyomasztó
                                hangulatra vágyik, nézze meg a Black Mirror aktuális részét</p>

                            <p>46. <strong>Karácsonyi csók</strong> – sose csókold meg a
                                főnököd pasiját</p>

                            <p>47. <strong>Mikulásné kerestetik</strong> – és ismét
                                feleséget keresnek a Télapónak</p>

                            <p>48. <strong>Rabold el a télapót</strong> – amikor a Télapót
                                betörőnek nézik</p>

                            <p>49. <strong>Kedves Télapó</strong> – bizony, van, aki
                                megválaszolja a Télapónak írt leveleket</p>

                            <p>50. <strong>A pót télapó</strong> – szívtelen nő meglágyul a
                                Télapónak hála</p>

                            <p>51. <strong>Madagaszkarácsony</strong> – a madagaszkári
                                állatok ünnepelnek</p>

                            <p>52. <strong>D</strong><strong>enisz a komisz
                                karácsonya</strong>- éppen neki ne lenne karácsonyi története?</p>

                            <p>53. <strong>Snoopy és a karácsony</strong> – Charlie Brown,
                                Snoopy és az ünnepek</p>

                            <p>54. <strong>Rudolf a rénszarvas</strong>- igen, ez sem csak
                                egy karácsonyi dal</p>

                            <p>55. <strong>Muppetek karácsonyi éneke</strong>- karácsoyi
                                sztori Muppet-módra</p>

                            <p>56.<strong>Az öt lgenda</strong>- Húsvéti Nyuszi, Fogtündér,
                                Mikulás…</p>

                            <p>57. <strong>Barbie és a Diótörő</strong>- Barbie, animáció,
                                balett</p>

                            <p>58. <strong>A karácsonyfa dísze</strong> – a reményt
                                jelképező karácsonyfadísz</p>

                            <p>59. <strong>A karácsonyi szív </strong>- kisfiú,
                                szívátültetés, karácsonyi fények</p>

                            <p>60. <strong>A karácsonyi vakáció –</strong> a Télapónak lánya
                                is van</p>

                            <p>61. <strong>A karácsonymanó meséje – </strong>karácsonyi
                                animációs mese, inkább gyerekeknek</p>

                            <p>62. <strong>Hivatali karácsony</strong> – amikor eldurvul a
                                céges buli</p>

                            <p>63. <strong>Káosz karácsonyra</strong> – tökéletes karácsony
                                lehetne a családdal</p>

                            <p>64.<strong> </strong><strong>Karácsonyi naptárpasik
                                –</strong> montanai szerelmi sokszög</p>

                            <p>65. <strong>Ki segít a mikulásnak? - </strong>Mikulás
                                főmanója, gonosz üzletember, Mikulás</p>

                            <p>66. <strong>Kiskarácsony mindenáron</strong> – karácsonyi
                                hangulat felsőfokon</p>

                            <p>67. <strong>Jégkorszak-Állati nagy karácsony</strong> – pont
                                nekik lenne ne karácsonyuk?</p>

                            <p>68. <strong>Karácsonyi csínytevő</strong>- női tolvaj
                                Cunnecticutban… igen, van köze a karácsonyhoz</p>

                            <p>69.<strong>Aludj csak, én álmodom</strong> – szerelem és
                                dráma karácsonyra</p>

                            <p>70. <strong>Bidget Jones </strong>&nbsp;– van ember a földön,
                                aki el tudja felejteni a szarvasos pulcsit?</p>

                            <p>71. <strong>Karácsonyi kutyabalhé </strong>– Disney mese
                                kutyával</p>

                            <p>72. <strong>Karácsonyi csoda </strong>– családi „akciófilm”
                                az ünnepekre</p>

                            <p>73. <strong>Karácsonyi kívánságok</strong> – most nem kell
                                vigyázni, hogy mit kívánsz</p>

                            <p>74. <strong>Egész évben farsang</strong>- bizony, a White
                                Christmas című sláger innen indult</p>

                            <p>75. <strong>Az élet csodaszép </strong>– kétségbeesett
                                fiatalember és az őrangyala</p>

                            <p>76. <strong>Folytassa karácsonykor </strong>- a Folytassa
                                sorozat karácsonyi kiadása</p>

                            <p>77. <strong>Drágán add az életed</strong> – nincs mit tenni,
                                vétek lenne kihagyni</p>

                            <p>78. <strong>Füst </strong>– kissé szomorú, azonban gyönyörű
                                emberi történetek</p>

                            <p>79. <strong>Twist Olivér </strong>– klasszikus történet
                                karácsonyra</p>

                            <p>80. <strong>Angyali üzlet </strong>– anya, lánya és egy kis
                                színjáték</p>

                            <p>81. <strong>Garfield karácsonya</strong>- persze, hogy neki
                                is van</p>

                            <p>82. <strong>Hupikék Törpikék- Karácsonyi ének</strong> –
                                természetesen nekik is ...</p>

                            <p>83. <strong>Karácsony a kastélyban</strong> – tipikus nem
                                gagyin vicces, hanem szép családi film</p>

                            <p>84. <strong>Volt egyszer egy karácsony</strong> – amikor a
                                Mikulás is besokall a karácsonytól</p>

                            <p>85. <strong>Egy fiú karácsonya</strong> – nagypapa mesél az
                                unokájának</p>

                            <p>86. <strong>Tapsi Hapsi bolondos karácsonyi meséi </strong>–
                                természetesen...</p>

                            <p>87. <strong>A Flinstone család: Kőkorszaki
                                karácsony</strong> – és igen, ők is itt vannak</p>

                            <p>88. <strong>A legszebb karácsonyi ajándék</strong> –
                                időjárásátalakító-gép Los Angelesben</p>

                            <p>89. <strong>Jégvarázs </strong>– minden kis- és nagylány
                                aktuális kedvenc Disney meséje</p>

                            <p>90. <strong>Karácsony előtt </strong>– magyar rövidfilm</p>

                            <p>91. <strong>Karácsonyi történet</strong> – úgy tűnik, hogy
                                légpuskát kérni karácsonyra nem jó ötlet</p>

                            <p>92. <strong>Kétséges karácsony</strong> – a Mikulás álruhában
                            </p>

                            <p>93. <strong>Milliomos Mikulás</strong> – egy éjszaka csodákra
                                képes</p>

                            <p>94. <strong>Szuperkarácsony</strong> – amikor rájössz, hogy a
                                tökéletesség nem a legjobb</p>

                            <p>95. <strong>Régimódi karácsony</strong> – Írország,
                                karácsony, titkok</p>

                            <p>96. <strong>Nagypapát kérek karácsonyra! </strong>- unoka,
                                nagypapa, baleset, karácsony</p>

                            <p>97. <b>Princess Switch -&nbsp;</b>a Netflix és Vanessa
                                Hudgens közös cukisága</p>

                            <p>98. <b>Rossz anyák karácsonya -&nbsp;</b>nem biztos, hogy így
                                képzelted el az ünnepet</p>

                            <p>99. <strong>A diótörő és a négy birodalom</strong> – a
                                diótörő története kicsit újragondolva</p>

                            <p>100. <strong>Családi karácsony</strong> - két ünnep között
                                irány a mozi!</p>
                        </div>
                    </div>
                </div>
            </>
    },
    {
        id: 14, content:
            <>
                <h2>Könyvajánló az ünnepre</h2>
                <div>
                    <img src="assets/konyv.jpg" alt="" className={'my-4 mx-auto'}/>
                </div>
                <h4>Szórakoztató és szívmelengető karácsonyi románc. Tökéletes olvasmány mindazoknak, akik szeretik a
                    Hugh Grant-féle romantikus vígjátékokat.</h4>
            </>
    },
    {
        id: 15, content:
            <>
                <h4 className={'text-center'}>Szeretnél résztvenni adventi sorsolásunkon?</h4>
                <h2 className={'text-center md:text-[30px] md:leading-[32px]'}>A nyertes 20.000 Ft értékű könyvutalványt
                    kap
                    ajándékba! </h2>
                <div>
                    <img src="assets/rajz.png" alt="" className={'my-5 mx-auto md:max-w-[600px]'}/>
                </div>
                <h4 className={'text-center'}>Jelentkezz neved és e-mail címed megadásával</h4>
                <div className={'flex max-w-[500px] mx-auto flex-col'}>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="name"
                                                                                className={'bg-fontBase p-2 pr-16 text-white font-bold rounded-l-lg uppercase'}>Név</label><input
                        type="text"
                        id={'name'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="email"
                                                                                className={'bg-fontBase p-2  text-white font-bold rounded-l-lg uppercase'}>Email&nbsp;Cím</label><input
                        type="email" id={'email'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div><input type="checkbox" id={'checkbox'}/><label htmlFor="checkbox"> Adatvédelmi és
                        játékszabályzatot
                        megértettem és elfogadom.</label></div>
                    <div className="btn-primary mt-4">Beküldés</div>
                </div>
            </>
    },
    {
        id: 16, content:
            <>
                <Game1/>
            </>
    },
    {
        id: 17, content:
            <>
                <h2>Mézeskalácsos trüffelgolyó
                    - tuti karácsonyi ajándék</h2>
                <div>
                    <img src="assets/Group 361.png" alt="" className={'my-4'}/>
                </div>
                <h4>Ismét egy szuper karácsonyi ajándékötlet, ami tényleg sokáig eláll a hűtőben. Használjatok jó
                    minőségű
                    étcsokit, sok-sok fűszert és magas kakaótartalmú, holland kakaóport és higgyétek el, hogy isteni
                    finom
                    lesz
                    a végeredmény ;)</h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>120 ml zsíros tejszín</li>
                            <li>300 g étcsoki</li>
                            <li>100 g puha vaj</li>
                            <li>1 mk őrölt gyömbér</li>
                            <li>1 mk őrölt szegfűszeg</li>
                            <li>2 mk őrölt fahéj</li>
                            <li>1/2 mk őrölt szerecsendió</li>
                            <li>1 db narancs reszelt héja</li>
                            <li>3 ek holland kakaópor</li>
                        </ul>
                    </div>
                    <div className={'col-span-3'}>
                        <p>A gasztroajándékkal sosem lőhettek mellé, így akiknek nincs ötletetek, hogy mit adjatok
                            karácsonyra,
                            egy saját készítésű, ünnepi csomagolású süti mindig jó meglepetés. A mézeskalácsos
                            trüffelgolyóval
                            biztos sikeretek lesz. A kevésbé édesszájú ismerősök is odáig lesznek érte, és az a jó
                            benne,
                            hogy
                            nem kell rengeteg energiát fektetni az elkészítésébe.
                        </p>
                        <p>
                            Az étcsokit leöntjük a felmelegített tejszínnel, belerakjuk a puha vajat, reszelt
                            narancshéjat,
                            és
                            beleszórjuk a fűszereket. Elkeverjük, hogy a vaj és a csoki is feloldódjon a meleg
                            tejszínben.
                        </p>
                        <p>
                            Az alapot bedobjuk a hűtőbe, időnként átkeverjük, és amikor már megdermedt, kézzel kisebb
                            golyókat
                            formálunk a masszából, és meghempergetjük őket a kakaóporban. A karácsonyi asztalon is
                            találhatjuk,
                            de pici zacskókba rakva szuper ajándék lesz, a hűtőben pedig sokáig eláll.
                        </p>
                    </div>
                </div>
            </>
    }
    ,
    {
        id: 18, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Ye2gMvRa-iE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/Ye2gMvRa-iE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    }
    ,
    {
        id: 19, content:
            <>
                <h4 className={'text-center'}>Szeretnél résztvenni adventi sorsolásunkon?</h4>
                <h2 className={'text-center md:text-[30px] md:leading-[32px]'}>A nyertes 20.000 Ft értékű könyvutalványt
                    kap
                    ajándékba! </h2>
                <div>
                    <img src="assets/rajz.png" alt="" className={'my-5 mx-auto md:max-w-[600px]'}/>
                </div>
                <h4 className={'text-center'}>Jelentkezz neved és e-mail címed megadásával</h4>
                <div className={'flex max-w-[500px] mx-auto flex-col'}>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="name"
                                                                                className={'bg-fontBase p-2 pr-16 text-white font-bold rounded-l-lg uppercase'}>Név</label><input
                        type="text"
                        id={'name'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="email"
                                                                                className={'bg-fontBase p-2  text-white font-bold rounded-l-lg uppercase'}>Email&nbsp;Cím</label><input
                        type="email" id={'email'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div><input type="checkbox" id={'checkbox'}/><label htmlFor="checkbox"> Adatvédelmi és
                        játékszabályzatot
                        megértettem és elfogadom.</label></div>
                    <div className="btn-primary mt-4">Beküldés</div>
                </div>
            </>
    }
    ,
    {
        id: 20, content:
            <>
                <Game1/>
            </>
    }
    ,
    {
        id: 21, content:
            <>
                <h2>Mézeskalácsos trüffelgolyó
                    - tuti karácsonyi ajándék</h2>
                <div>
                    <img src="assets/Group 361.png" alt="" className={'my-4'}/>
                </div>
                <h4>Ismét egy szuper karácsonyi ajándékötlet, ami tényleg sokáig eláll a hűtőben. Használjatok jó
                    minőségű
                    étcsokit, sok-sok fűszert és magas kakaótartalmú, holland kakaóport és higgyétek el, hogy isteni
                    finom
                    lesz
                    a végeredmény ;)</h4>
                <div className="grid md:grid-cols-4 ">
                    <div>
                        <h3>Hozzávalók</h3>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>120 ml zsíros tejszín</li>
                            <li>300 g étcsoki</li>
                            <li>100 g puha vaj</li>
                            <li>1 mk őrölt gyömbér</li>
                            <li>1 mk őrölt szegfűszeg</li>
                            <li>2 mk őrölt fahéj</li>
                            <li>1/2 mk őrölt szerecsendió</li>
                            <li>1 db narancs reszelt héja</li>
                            <li>3 ek holland kakaópor</li>
                        </ul>
                    </div>
                    <div className={'col-span-3'}>
                        <p>A gasztroajándékkal sosem lőhettek mellé, így akiknek nincs ötletetek, hogy mit adjatok
                            karácsonyra,
                            egy saját készítésű, ünnepi csomagolású süti mindig jó meglepetés. A mézeskalácsos
                            trüffelgolyóval
                            biztos sikeretek lesz. A kevésbé édesszájú ismerősök is odáig lesznek érte, és az a jó
                            benne,
                            hogy
                            nem kell rengeteg energiát fektetni az elkészítésébe.
                        </p>
                        <p>
                            Az étcsokit leöntjük a felmelegített tejszínnel, belerakjuk a puha vajat, reszelt
                            narancshéjat,
                            és
                            beleszórjuk a fűszereket. Elkeverjük, hogy a vaj és a csoki is feloldódjon a meleg
                            tejszínben.
                        </p>
                        <p>
                            Az alapot bedobjuk a hűtőbe, időnként átkeverjük, és amikor már megdermedt, kézzel kisebb
                            golyókat
                            formálunk a masszából, és meghempergetjük őket a kakaóporban. A karácsonyi asztalon is
                            találhatjuk,
                            de pici zacskókba rakva szuper ajándék lesz, a hűtőben pedig sokáig eláll.
                        </p>
                    </div>
                </div>
            </>
    }
    ,
    {
        id: 22, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Ye2gMvRa-iE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/Ye2gMvRa-iE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    }
    ,
    {
        id: 23, content:
            <>
                <h4 className={'text-center'}>Szeretnél résztvenni adventi sorsolásunkon?</h4>
                <h2 className={'text-center md:text-[30px] md:leading-[32px]'}>A nyertes 20.000 Ft értékű könyvutalványt
                    kap
                    ajándékba! </h2>
                <div>
                    <img src="assets/rajz.png" alt="" className={'my-5 mx-auto md:max-w-[600px]'}/>
                </div>
                <h4 className={'text-center'}>Jelentkezz neved és e-mail címed megadásával</h4>
                <div className={'flex max-w-[500px] mx-auto flex-col'}>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="name"
                                                                                className={'bg-fontBase p-2 pr-16 text-white font-bold rounded-l-lg uppercase'}>Név</label><input
                        type="text"
                        id={'name'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div className={'flex  items-center mb-5 shadow-lg'}><label htmlFor="email"
                                                                                className={'bg-fontBase p-2  text-white font-bold rounded-l-lg uppercase'}>Email&nbsp;Cím</label><input
                        type="email" id={'email'}
                        className={'w-full rounded-lg bg-white text-black px-5 border-none p-2 placeholder:text-slate-700 placeholder:font-bold'}/>
                    </div>
                    <div><input type="checkbox" id={'checkbox'}/><label htmlFor="checkbox"> Adatvédelmi és
                        játékszabályzatot
                        megértettem és elfogadom.</label></div>
                    <div className="btn-primary mt-4">Beküldés</div>
                </div>
            </>
    }
    ,
    {
        id: 24, content:
            <>
                <h2>Karácsonyi üdvözlet</h2>
                <div>
                    <img src="assets/karacsony.jpg" alt="" className={'my-4 mx-auto'}/>
                </div>

            </>
    }
    ,
]
